import React from "react"
import styled, { ThemeProvider } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"
import { customMedia } from "../../styles/customMedia"
import { defaultTheme } from "../../styles/theme"
import GlobalStyles from "../../styles/global"
import Button from "../../components/commons/Button"
import Typography from "../../components/commons/Typography"
import {
  Footer,
  HeroContentFunds,
  FundsForm,
  FundsInformations,
  FundsBenefits,
} from "../../components"
import FundsBackground from "../../../static/funds-background.png"

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  ${customMedia.greaterThan("mediumDesktop")`
    border-radius: 0;
  `}
`

export const BackgroundContainer = styled.div`
  background: linear-gradient(90deg, #151b1e 0%, #151b1e00 100%),
    url("${FundsBackground}");

  ${customMedia.greaterThan("mediumDesktop")`
    background-position: 0 30%;
    border-radius: 0;
  `}

  ${customMedia.greaterThan("hugeDesktop")`
    background-position: 0 50%;
  `}
`

// Z-INDEX: 3, maior que a legenda da foto do CEO, menor do que os elements que escondem ele
export const FixedButtonFooter = styled.div`
  width: 100%;
  height: 48px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  z-index: 3;
`
export const HiddenFixedButtonHandler = styled.span`
  z-index: 4;
`

function Fundos() {
  const {
    typography: { dark },
    colors: { lightOrange },
  } = defaultTheme

  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet title="Fundos de Investimentos - EQI" defer={false}/>
      <GlobalStyles />
      <MainContainer>
        <BackgroundContainer>
          <HeroContentFunds />
        </BackgroundContainer>
        <FundsForm />
        <FundsInformations />
        <FundsBenefits />
        <FixedButtonFooter>
          <Button onClick={() => scrollTo("#form")} bgcolor={lightOrange}>
            <Typography type="p" color={dark} size="14px" weight="bold">
              QUERO SABER MAIS
            </Typography>
          </Button>
        </FixedButtonFooter>
        <HiddenFixedButtonHandler>
          <Footer />
        </HiddenFixedButtonHandler>
      </MainContainer>
    </ThemeProvider>
  )
}

export default Fundos
